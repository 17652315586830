export const UPDATE_USER = 'UPDATE_USER'

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'

export const UPDATE_SINGLE_ORGANIZATION = 'UPDATE_SINGLE_ORGANIZATION'
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'

export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS'
export const UPDATE_SINGLE_CUSTOMER = 'UPDATE_SINGLE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'

export const UPDATE_TALENTS = 'UPDATE_TALENTS'
export const DELETE_TALENT = 'DELETE_TALENT'
export const UPDATE_AGGREGATED_TALENTS = 'UPDATE_AGGREGATED_TALENTS'
export const UPDATE_SINGLE_AGGREGATED_TALENT = 'UPDATE_SINGLE_AGGREGATED_TALENT'

export const FIXED_VACATION_DAYS = 'FIXED_VACATION_DAYS'
export const AVAILABLE_VACATION_DAYS = 'AVAILABLE_VACATION_DAYS'

export const VACATION_HISTORY_PUSH = 'VACATION_HISTORY_PUSH'
export const VACATION_HISTORY_UPDATE = 'VACATION_HISTORY_UPDATE'
export const ALL_VACATION_HISTORY = 'ALL_VACATION_HISTORY'
export const BASIC_VACATION_VALUES = 'BASIC_VACATION_VALUES'
export const USED_DAYS_VALUES = 'USED_DAYS_VALUES'
export const ALLOWED_VACATION_DAYS = 'ALLOWED_VACATION_DAYS'

export const PUSH_UPCOMING_HOLIDAYS = 'PUSH_UPCOMING_HOLIDAYS'
export const PUSH_UPCOMING_ANNIVERSARIES = 'PUSH_UPCOMING_ANNIVERSARIES'
export const PUSH_UPCOMING_BIRTHDAY = 'PUSH_UPCOMING_BIRTHDAY'

export const PUSH_AGENCIES = 'PUSH_AGENCIES'
export const UPDATE_AGENCIES = 'UPDATE_AGENCIES'
export const DELETE_AGENCY = 'DELETE_AGENCY'
export const PUSH_FEEDBACKS = 'PUSH_FEEDBACKS'
export const PUSH_QUESTIONS = 'PUSH_QUESTIONS'

export const PUSH_ON_LEAVE = 'PUSH_ON_LEAVE'
export const UPDATE_GLOBAL_VACATION_HISTORY = 'UPDATE_GLOBAL_VACATION_HISTORY'

export const PUSH_REPORT_RECORDS = 'PUSH_REPORT_RECORDS'
export const PUSH_TABLE_IDS = 'PUSH_TABLE_IDS'

export const RESET_STATE = 'RESET_STATE'

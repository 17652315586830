import { ReactComponent as close } from './ico-close.svg';
import { ReactComponent as done } from './ico-done.svg';
import { ReactComponent as roundClose } from './ico-close-circle.svg';
import { ReactComponent as mail} from './mail.svg';
import { ReactComponent as password} from './password.svg';
import { ReactComponent as user} from './user-circle.svg';
import { ReactComponent as logout } from './logout.svg'
import { ReactComponent as dashboard } from './dashboard-nav.svg'
import { ReactComponent as dashboardInactive } from './dashboard-unactive.svg'
import { ReactComponent as userDark } from './user-circle-black.svg'
import { ReactComponent as vacationCircle } from './vacation_circle.svg'
import { ReactComponent as vacationIcon } from './vacation_count_icon.svg'
import { ReactComponent as sickCircle } from './sick_circle.svg'
import { ReactComponent as sickIcon } from './sick_icon.svg'
import { ReactComponent as unpaidCircle } from './unpaid_circle.svg'
import { ReactComponent as unpaidIcon } from './unpaid_icon.svg';
import { ReactComponent as calendar } from './calendaric.svg'
import { ReactComponent as calendarChecked } from './calendar-check.svg'
import { ReactComponent as vacationModal } from './vacation_modal.svg'
import { ReactComponent as selectIcon } from './select-icon.svg'
import { ReactComponent as closeModal } from './close_modal.svg'
import { ReactComponent as alert } from './alert.svg'
import { ReactComponent as vacationSuccess } from './vacation_approved.svg'
import { ReactComponent as onLeave } from './on_leave.svg'
import { ReactComponent as calendarStar } from './calendar_star.svg'
import { ReactComponent as calendarHeart } from './calendar_heart.svg'
import { ReactComponent as customersActive } from './customers_active.svg'
import { ReactComponent as customerInactive } from './customer_inactive.svg'
import { ReactComponent as employeeActive } from './employee_active.svg'
import { ReactComponent as employeeInactive } from './employees_inactive.svg'
import { ReactComponent as employee } from './employees.svg'
import { ReactComponent as deleteIcon } from './delete_icon.svg'
import { ReactComponent as addUser } from './user_add_icon.svg'
import { ReactComponent as search } from './search.svg'
import { ReactComponent as addUserGray } from './user_add_gray.svg'
import { ReactComponent as imageCircle } from './image_circle.svg'
import { ReactComponent as imageIcon } from './image_icon.svg'
import { ReactComponent as editIcon } from './edit_icon.svg'
import { ReactComponent as lockIcon } from './lock_icon.svg'
import { ReactComponent as biggerLockIcon } from './lock_big_icon.svg'
import { ReactComponent as hiddenPasswordIcon } from './hide_password.svg'
import { ReactComponent as visiblePasswordIcon } from './show_password.svg'
import { ReactComponent as bellIcon } from './bell_icon.svg'
import { ReactComponent as registeredLeaves} from './registered_leaves_back.svg';
import { ReactComponent as calendarDotes } from './calendar_dotes.svg'
import { ReactComponent as customerDefault } from './customer_default.svg'
import { ReactComponent as sendMail } from './send_mail_icon.svg';
import { ReactComponent as userEditIcon } from './user_edit_icon.svg'
import { ReactComponent as orgz } from './orgz.svg'
import { ReactComponent as orgzActive } from './orgz_active.svg'
import { ReactComponent as orgzInactive } from './orgz_inactive.svg'
import { ReactComponent as feedback } from './feedback_icon.svg'
import { ReactComponent as feedbackActive } from './feedback_active.svg'
import { ReactComponent as feedbackInactive } from './feedback_unactive.svg'
import { ReactComponent as downloadIcon } from './download_icon.svg'
import { ReactComponent as arrowLeft } from './arrow_left.svg'
import { ReactComponent as arrowRight } from './arrow_right.svg'
import { ReactComponent as feedbackHeart } from './itsoft_heart.svg'
import { ReactComponent as reportIcon } from './report_icon.svg'
import { ReactComponent as reportActive } from './report_active.svg'
import { ReactComponent as reportInactive } from './report_default.svg'

const iconsObj = {
    close,
    done,
    roundClose,
    mail,
    password,
    user,
    logout,
    dashboard,
    dashboardInactive,
    userDark,
    vacationCircle,
    vacationIcon,
    sickCircle,
    sickIcon,
    unpaidCircle,
    unpaidIcon,
    calendar,
    calendarChecked,
    vacationModal,
    selectIcon,
    closeModal,
    alert,
    vacationSuccess,
    onLeave,
    calendarStar,
    calendarHeart,
    customersActive,
    customerInactive,
    employeeActive,
    employeeInactive,
    employee,
    deleteIcon,
    addUser,
    search,
    addUserGray,
    imageCircle,
    imageIcon,
    editIcon,
    lockIcon,
    biggerLockIcon,
    hiddenPasswordIcon,
    visiblePasswordIcon,
    bellIcon,
    registeredLeaves,
    calendarDotes,
    customerDefault,
    sendMail,
    userEditIcon,
    orgzActive,
    orgzInactive,
    orgz,
    feedback,
    feedbackActive,
    feedbackInactive,
    downloadIcon,
    arrowLeft,
    arrowRight,
    feedbackHeart,
    reportIcon,
    reportInactive,
    reportActive
}

export default iconsObj